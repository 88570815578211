// import styles of this component
import styles from "./Nav.module.css";

// import other components
import Button from "../Elements/Button/Button";

// import other react pkg to use
import { HambergerMenu } from "iconsax-react";

// Nav component
const Nav = () => {
  return (
    <nav className={`${styles.nav} flex align-items-center`}>
      <h1 className={styles["nav-title"]}>
        Gift <br /> Galore
      </h1>
      <ul className={`flex align-items-center ${styles["navbar-nav"]}`}>
        <li className={`${styles["nav-item"]} ${styles.active}`}>
          <a href="" className={styles["nav-link"]}>
            By Siddhi J.
          </a>
        </li>
        {/* <li className={styles["nav-item"]}>
                <a href="" className={styles["nav-link"]}>Wallpapers</a>
            </li>
            <li className={styles["nav-item"]}>
                <a href="" className={styles["nav-link"]}>Collections</a>
            </li>
            <li className={styles["nav-item"]}>
                <a href="" className={styles["nav-link"]}>Artists</a>
            </li>
            <li className={`${styles["nav-item"]} ${styles["d-none-1100"]}`}>
                <a href="" className={styles["nav-link"]}>Explore</a>
            </li>
            <li className={`${styles["nav-item"]} ${styles["d-none-1100"]}`}>
                <a href="" className={styles["nav-link"]}>Blog</a>
            </li> */}
      </ul>
      <div className={`flex ${styles["navbar-buttons"]}`}>
        <a href="https://api.whatsapp.com/send/?phone=%2B919307412613&text=Hi+Gift+Galore%2C%0AI'm+interested+in+placing+an+order+for%0A.%0A.%0A.%0AFollow+us+on+Instagram%0Ahttps%3A%2F%2Fwww.instagram.com%2Fgift_galore28&type=phone_number&app_absent=0">
          <Button theme="matrix">Contact</Button>
        </a>
      </div>
      <div className={styles["navbar-responsive-menu"]}>
        <Button theme="transparent">
          <HambergerMenu size="32" color="var(--white-100)" />
        </Button>
      </div>
    </nav>
  );
};

export default Nav;
